<template>
  <section>
    <escolaLista/>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import escolaLista from "@/components/escolas/escolaAll.vue"

export default defineComponent({
  components:{
    escolaLista
  },
  data() {
    return {
    };
  },
});
</script>
